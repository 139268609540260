export enum PlanFrequency {
    Month = 'MONTH',
    Year = 'YEAR',
    Week = 'WEEK',
    Day = 'DAY',
}

export enum SubscriptionStatus {
    NotConfigured = 'NOT_CONFIGURED',
    Incomplete = 'INCOMPLETE',
    Active = 'ACTIVE',
    Failed = 'FAILED',
}

export enum PriceName {
    ClientPremiumMonthly = 'client_premium_monthly',
    ClientPremiumYearly = 'client_premium_yearly',
    ProviderBasicMonthly = 'provider_basic_monthly',
    ProviderBasicYearly = 'provider_basic_yearly',
    ProviderProMonthly = 'provider_pro_monthly',
    ProviderProYearly = 'provider_pro_yearly',
}

export interface IPortalSession {
    url: string;
}

export interface IPortalSessionCreate {
    userID: string;
    returnURL: string;
}

export interface ICheckoutSessionCreate {
    userID: string;
    successURL: string;
    cancelURL: string;
    priceName: string;
}

export interface IBillingPlan {
    product_id: string;
    price_id: string;
    price_key?: string;
    name: string;
    description: string;
    currency: string;
    price: number;
    frequency: PlanFrequency;
}

export interface IBillingSubscription {
    user_id: string;
    customer_id?: string;
    plan?: IBillingPlan;
    status: SubscriptionStatus;
}
